/* Chat container styles */
.chat-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  margin: 10px;
  padding: 10px 20px;
  background-color: #fff; /* White background for chat area */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Lighter shadow for subtlety */
}

/* Message bubble styles */
.message {
  max-width: 75%;
  margin: 10px auto; /* Increased margin and auto for centering */
  padding: 12px 16px;
  border-radius: 20px; /* Rounder edges for bubbles */
  line-height: 1.5;
  font-size: 0.9rem; /* Slightly smaller font size */
  font-weight: 400; /* Normal font weight */
  word-wrap: break-word;
  position: relative; /* For absolute positioning of pseudo-elements */
}

/* Assistant message styles */
.message:not(.message-personal) {
  background-color: #ecf0f1; /* Light grey background for assistant messages */
  color: #2c3e50; /* Darker text for contrast and readability */
  /* Offset to the left */
  transform: translateX(-10%);
}

/* User message styles */
.message-personal {
  background-color: #3498db; /* Soft blue for personal messages */
  color: #fff;
  /* Offset to the right */
  transform: translateX(10%);
}

/* Message input box styles */
.message-box {
  display: flex;
  align-items: center;
  padding: 12px 18px;
  background-color: #f4f4f4; /* Lighter grey to differentiate from the message area */
  border-top: 1px solid #dbdbdb; /* Soft border top */
}

/* Message input styles */
.message-input {
  flex-grow: 1;
  border: 1px solid #dbdbdb; /* Matching the border to the box */
  padding: 10px 15px;
  border-radius: 20px; /* Rounder edges to match the message bubbles */
  margin-right: 12px;
  background-color: #fff; /* White background for input */
  font-size: 0.9rem; /* Matching font size */
}

/* Send button styles */
.message-submit {
  background-color: #3498db; /* Matching the user message bubble */
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.1s;
}

.message-submit:hover, .message-submit:focus {
  background-color: #2980b9; /* A shade darker on interaction */
  transform: translateY(-1px); /* Subtle lift effect */
}

/* Placeholder to match the design */
.message-input::placeholder {
  color: #95a5a6; /* Grey placeholder text */
}

/* Adjust scroll for a better look */
.messages {
  overflow-y: auto;
  padding-right: 5px; /* To prevent scrollbar from overlapping messages */
}

.messages::-webkit-scrollbar {
  width: 6px; /* Slimmer scrollbar */
}

.messages::-webkit-scrollbar-thumb {
  background: #bdc3c7; /* Scroll thumb in a neutral grey */
  border-radius: 10px;
}

.messages::-webkit-scrollbar-track {
  background: transparent;
}