/* General styling for the pagination component */
:root {
  --primary-color: #3F51B5;
  --primary-color-100: #C5CAE9;
  --primary-color-50: #E8EAF6;
  --text-primary-color: RGBA(0, 0, 0, 0.95);
  --text-secondary-color: RGBA(0, 0, 0, 0.64);
  --border-radius: 12px;
}

.react-paginate {
  /*margin-bottom: 2rem;*/
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  padding: 0 5rem;
}

.react-paginate li a {
  /*border-radius: 7px;*/
  padding: 0.1rem 1rem;
  /*border: gray 1px solid;*/
  cursor: pointer;
  text-decoration: none;
}

.react-paginate li.previous a,
.react-paginate li.next a,
.react-paginate li.break a {
  /*border-color: transparent;*/
}

.react-paginate li.active a {
  background-color: #0366d6;
  /*border-color: transparent;*/
  color: white;
  min-width: 32px;
}

.react-paginate li.disabled a {
  color: grey;
  cursor: default;
}

/* Comment list styling */
.commentList {
  margin-top: 2rem;
}

.commentList .list-group-item {
  border: 1px solid #ddd;
  margin-bottom: 1rem;
  padding: 1rem;
}

.commentList .list-group-item h5 {
  font-size: 1.2rem;
  color: #333;
}

.commentList .list-group-item small {
  color: #777;
}

/* Bootstrap-like pagination controls */
.pagination {
  display: flex;
  justify-content: center;
  padding-left: 0;
  list-style: none;
  align-items: center;
}

.page-item {
  margin: 0 0.25rem;
}

.page-link {
  display: block;
  padding: 0.5rem;
  color: var(--text-secondary-color);
  text-decoration: none;
  /*background-color: white;*/
  /*border: 1px solid #dee2e6;*/
  /*border-radius: 0.25rem;*/
  cursor: pointer;
  transition: all .2s ease-in-out;
  opacity: 0.6;
  font-size: 1rem;
}

.page-link:hover {
  color: #0056b3;
  text-decoration: none;
  /*background-color: #e9ecef;*/
  opacity: 0.6;
  border-radius: var(--border-radius);
  transition: all .2s ease-in-out;
}

.page-item.active .page-link {
  z-index: 1;
  color: var(--primary-color);
  font-weight: bold;
  opacity: 1;
  /*transform: scale(1.3);*/
  /*text-decoration: underline;*/
  /*background-color: var(--primary-color-50);*/
  /*border-radius: 100%;*/
}

.page-item.disabled .page-link {
  color: var(--text-secondary-color);
  pointer-events: none;
  display: none;
  /*background-color: white;*/
  /*border-color: #dee2e6;*/
}